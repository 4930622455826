<template>
  <div>
    通知管理
    <!-- 操作按钮取 -->
    <div class="btns-bar">
      <el-button type="primary" @click="showAddDialog = true">添加通知</el-button>
      <div class="tip">通知类型：0：赛事上新通知，1：官方通知，2：报名截止通知，3：赛事优惠通知，4：赛事延期/取消通知</div>
    </div>
    <!-- 表格展示 -->
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="_id" label="通知ID" width="230"></el-table-column>
      <el-table-column prop="title" label="通知标题" width="300"></el-table-column>
      <el-table-column prop="content" label="通知内容" width="500"></el-table-column>
      <el-table-column prop="type" label="通知类型" width="50"></el-table-column>
      <el-table-column prop="url" label="通知指向链接" width="200"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.row._id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加对话框 -->
    <el-dialog
      title="添加通知"
      :visible.sync="showAddDialog"
      width="50%"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="通知标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="通知内容" prop="content">
          <el-input type="textarea" autosize v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item label="通知类型" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio :label="0">赛事上新通知</el-radio>
            <el-radio :label="1">官方通知</el-radio>
            <el-radio :label="2">报名截止通知</el-radio>
            <el-radio :label="3">赛事优惠通知</el-radio>
            <el-radio :label="4">赛事延期/取消通知</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="通知指向链接" prop="url">
          <el-input v-model="form.url"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addAd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAddDialog: false,
      tableData: [],
      form: {
        title: "",
        content: "",
        type: 0,
        url: ""
      },
      rules: {
        title: [
          { required: true, message: "请输入通知标题", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        content: [
          { required: true, message: "请输入通知内容", trigger: "blur" },
          {
            min: 3,
            max: 500,
            message: "长度在 3 到 500 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getNoticeList()
  },
  methods: {
    getNoticeList() {
      this.$axios.get('/notice/find_all').then((res) => {
        this.tableData = res.data.res;
      }).catch((err) => {
        this.$message.error(err.response.data.message);
      })
    },
     addAd() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return this.$message.error("表单信息填写错误，请根据提示检查！");
        }
        this.$axios
          .post('/notice/add',
          JSON.stringify(this.form),
          {
            headers: {
              'x-auth-token': sessionStorage.getItem('bde_token')
            }
          })
          .then((res) => {
            if (res.data.code === "success") {
              this.$message.success("添加成功！");
            } else {
              this.$message.error("请求错误！");
            }
            this.showAddDialog = false
            this.getNoticeList();
          })
          .catch((err) => {
            this.$message.error(err.response.data.message);
          })
      });
    },
    handleDelete(id) {
      this.$confirm("此操作将永久删除该通知, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.requestDelete(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    requestDelete(id) {
      this.$axios
        .post('/notice/delete', 
        JSON.stringify({id: id}),
        {
          headers: {
            "x-auth-token": sessionStorage.getItem("bde_token"),
          },
        })
        .then((res) => {
          if (res.data.code === "success") {
            this.$message.success("删除成功");
            this.getNoticeList();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          if(err.response.data.code === 'token_timeout') {
            this.$router.push('/login')
          }
        });
    },
  }
};
</script>

<style scoped>
.btns-bar {
  margin: 20px 0;
}
.tip {
  margin-top: 20px;
  font-size: 14px;
  color: #999;
}
</style>
