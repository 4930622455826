import Vue from "vue";
import VueRouter from "vue-router";

import Index from "../views";
import EventManage from '../views/eventManage'
import AddEvent from '../views/addEvent'
import AddForm from '../views/addForm'
import NoticeManage from '../views/noticeManage'
import AdsManage from '../views/adsManage'
import VideoManage from '../views/videoManage'
import noticeManage from '../views/noticeManage'
import AllEvent from '../views/allEvent'
import Login from '../views/login'
import FormResultManage from '../views/formResultManage'
import EquipmentManage from '../views/equipmentManage'
import AddArticle from '../views/addArticle'
import ArticleManage from '../views/articleManage'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Index,
    children: [
      {
        path: "/",
        component: EventManage,
      },
      {
        path: "/manage",
        component: EventManage,
      },
      {
        path: "/add",
        component: AddEvent,
      },
      {
        path: "/add-form",
        component: AddForm,
      },
      {
        path: "/notice",
        component: NoticeManage,
      },
      {
        path: "/ads",
        component: AdsManage,
      },
      {
        path: "/notice",
        component: noticeManage,
      },
      {
        path: "/video",
        component: VideoManage,
      },
      {
        path: "/database",
        component: AllEvent,
      },
      {
        path: "/form_result",
        component: FormResultManage,
      },
      {
        path: "/equipment",
        component: EquipmentManage,
      },
      {
        path: "/add-article",
        component: AddArticle,
      },
      {
        path: "/article-list",
        component: ArticleManage,
      },
    ],
  },
  {
    path: "/login",
    name: 'Login',
    component: Login,
  },
];
const router = new VueRouter({
  routes,
});

import { Notification } from 'element-ui';
Vue.prototype.$notification = Notification;

// 挂载全局路由导航守卫
router.beforeEach((to,from,next) => {
  // to：将要访问的路径
  // from：从哪个路径跳转而来
  // next：是一个函数，表示放行
  const token =window.sessionStorage.getItem('bde_token')
  if(token){ 
      next();
  } else {
    if(to.path === '/login'){ 
      //如果是登录页面,就停留登录页
      next();
    } else { 
      //不然就跳转到登录
      Notification({
        title: '您还未登录',
        message: '请登录！',
        type: 'warning'
      });
      next('/login');
    }
  }
  next()
});

export default router;
