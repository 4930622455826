<template>
  <div v-loading="uploading">
    <el-form ref="form" :rules="rules" :model="form" label-width="150px">
      <el-form-item label="文章标题" prop="title">
        <el-input v-model="form.title" placeholder="请输入文章标题"></el-input>
      </el-form-item>
      <el-form-item label="文章作者" prop="author">
        <el-input v-model="form.author" placeholder="请输入文章作者"></el-input>
      </el-form-item>
      <el-form-item label="文章类型" prop="type">
        <el-radio-group v-model="form.type">
          <el-radio
            v-for="item in ARTICLE_TYPE"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="文章状态" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio
            v-for="item in ARTICLE_STATUS"
            :key="item.value"
            :label="item.value"
            >{{ item.label }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
      <el-form-item label="文章内容">
        <quill-editor
          ref="myQuillEditor"
          v-model="form.content"
          :options="editorOption"
        />
        <input type="file" @change="change" id="upload" style="display: none" />
      </el-form-item>
      <el-form-item label="文章头图">
        <el-upload
          class="image-upload"
          action=""
          :http-request="onUploadHeadImg"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :limit="1"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="imgPreviewDialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
        <el-input
          v-model="form.banner"
          placeholder="请输入文章头图链接"
        ></el-input>
      </el-form-item>
      <el-form-item label="标签">
        <el-input
          v-model="form.tagStr"
          placeholder="请输入赛事标签，以逗号分隔"
        ></el-input>
      </el-form-item>
      <el-form-item label="第三方地址">
        <el-input
          v-model="form.thirdUrl"
          placeholder="微信公众号地址或健美赛事网地址"
        ></el-input>
      </el-form-item>
      <el-form-item class="footer-btn">
        <el-button type="primary" @click="onSubmit">{{
          isEdit ? "保存修改" : "提交"
        }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { ARTICLE_TYPE, ARTICLE_STATUS } from "./constant";
import uploadMixin from "../../mixin/upload";
// eslint-disable-next-line no-unused-vars
import { quillEditor, Quill } from "vue-quill-editor";

import resizeImage from "quill-image-resize-module"; // 图片缩放组件引用
import { ImageDrop } from "quill-image-drop-module"; // 图片拖动组件引用
import { ImageExtend } from "quill-image-extend-module"; // 图片上传组件引用

Quill.register("modules/imageDrop", ImageDrop); // 注册
Quill.register("modules/resizeImage ", resizeImage); // 注册
Quill.register("modules/ImageExtend", ImageExtend); // 注册

const toolOptions = [
  ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
  ["blockquote", "code-block"], //引用，代码块
  [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
  [{ list: "ordered" }, { list: "bullet" }], //列表
  [{ script: "sub" }, { script: "super" }], // 上下标
  [{ indent: "-1" }, { indent: "+1" }], // 缩进
  [{ direction: "rtl" }], // 文本方向
  [{ size: ["small", false, "large", "huge"] }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
  [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
  [{ font: [] }], //字体
  [{ align: [] }], //对齐方式
  ["clean"], //清除字体样式
  ["image"], //上传图片、上传视频
];

export default {
  props: {
    formObj: {
      type: Object,
      default: () => ({
        title: "",
        author: "官方动态",
        status: 1,
        banner: "",
        content: "",
        type: 0,
        tagStr: "",
        thirdUrl: "",
      }),
    },
  },
  components: {
    quillEditor,
  },
  mixins: [uploadMixin],
  data() {
    return {
      uploading: false,
      editorOption: {
        modules: {
          ImageExtend: {
            loading: true,
            name: "img",
            action: "/abc",
            response: (res) => {
              console.log(res);
              return res.info;
            },
            // eslint-disable-next-line no-unused-vars
            change: (xhr, formData) => {},
          },
          toolbar: {
            container: toolOptions,
            handlers: {
              image: function (value) {
                if (value) {
                  document.querySelector("#upload").click(); // 劫持原来的图片点击按钮事件
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
          imageDrop: true, //图片拖拽
          imageResize: {
            //放大缩小
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
        },
      },
      editorContent: "",
      ARTICLE_TYPE,
      ARTICLE_STATUS,
      rules: {
        title: [
          { required: true, message: "请输入文章标题", trigger: "blur" },
          {
            min: 3,
            max: 200,
            message: "长度在 3 到 200 个字符",
            trigger: "blur",
          },
        ],
        author: [
          { required: true, message: "请输入文章作者", trigger: "blur" },
          {
            min: 1,
            max: 200,
            message: "长度在 2 到 200 个字符",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择文章类型",
            trigger: "change",
          },
        ],
      },
      editerTempImgUrl: ""
    };
  },
  computed: {
    form() {
      return this.formObj;
    },
    isEdit() {
      return !!this.$route.query.id;
    },
  },
  watch: {
    editerTempImgUrl(newVal) {
      // 若此时聚焦在输入框
      if (this.$refs.myQuillEditor.quill.hasFocus()) {
        this.insertEmbedImg(newVal);
      } else {
        this.formObj.banner = newVal;
      }
    }
  },
  mounted() {
    // 绑定上传成功监听函数
    this.$ttUploader.on("complete", (data) => {
      if (data.type === "success") {
        this.editerTempImgUrl =
          "https://cdn.jirou.fit/" +
          data.uploadResult.ImageUri +
          "~tplv-700tmnc25s-zipedpng75.png";
      }
      this.$message({
        type: "success",
        message: data.extra.message,
      });
      this.uploading = false;
    });
    // 绑定进度监听函数
    this.$ttUploader.on("progress", (infor) => {
      console.log(infor.percent);
      if (infor.percent !== 100) {
        this.uploading = true;
      }
    });
    // 绑定错误监听函数
    this.$ttUploader.on("error", (infor) => {
      this.uploading = false;
      this.$message({
        type: "error",
        message: infor.extra,
      });
    });

    if (!this.isEdit) return;
    this.getArticleDetail();
  },
  methods: {
    change(e) {
      let file = e.target.files[0];

      this.uploadImg({
        file,
      });
    },
    // 将上传完成的图片插入编辑器
    insertEmbedImg(img) {
      let quill = this.$refs.myQuillEditor.quill;

      let length = quill.getSelection().index; //光标位置
      // 插入图片  图片地址
      quill.insertEmbed(length, "image", img);
      // 调整光标到最后
      quill.setSelection(length + 1); //光标后移一位
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return this.$message.error("表单信息填写错误，请根据提示检查！");
        }
        let url = "";
        if (this.isEdit) {
          url = "/article/update";
        } else {
          url = "/article/add";
        }
        this.$axios
          .post(url, JSON.stringify(this.form), {
            headers: {
              "x-auth-token": sessionStorage.getItem("bde_token"),
            },
          })
          .then((res) => {
            if (res.data.code === "success") {
              this.$message.success("操作成功！");
              this.$emit("edit-success");
              this.$router.push("/article-list");
            } else {
              this.$message.error("请求错误！");
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.response.data.message);
            if (err.response.data.code === "token_timeout") {
              this.$router.push("/login");
            }
          });
      });
    },
    getArticleDetail() {
      this.$axios
        .post(
          "/article/find_by_id",
          JSON.stringify({
            id: this.$route.query.id,
          })
        )
        .then((res) => {
          if (res.data.code === "success") {
            this.formObj = res.data.res;
          } else {
            this.$message.error("请求错误！");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.response.data.message);
          if (err.response.data.code === "token_timeout") {
            this.$router.push("/login");
          }
        });
    },
    onUploadHeadImg(param) {
      this.d;
      // 上传图片到火山引擎
      this.uploadImg(param);
    },
  },
};
</script>

<style scoped>
.footer-btn {
  margin-top: 100px;
}
/deep/ .ql-picker-label::before {
  position: absolute;
  top: 0;
  left: 0;
}
/deep/ .ql-picker-label {
  display: flex !important;
}

/deep/ .ql-editor {
  height: 300px;
}

.image-upload {
  margin-bottom: 10px;
}
</style>
