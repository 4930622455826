<template>
  <div>
    <div class="filter-tools">
      <el-input placeholder="请输入关键词" v-model="query" class="query-input">
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleQuery"
        ></el-button>
      </el-input>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="_id" label="赛事ID" width="150"></el-table-column>
      <el-table-column prop="name" label="比赛名称" width="330">
      </el-table-column>
      <el-table-column prop="address" label="比赛地点" width="100">
      </el-table-column>
      <el-table-column label="比赛时间" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.fuzzyTime">{{ scope.row.fuzzyTime }}</span>
          <span v-else>
            {{ $dayjs(scope.row.startTime).format("YYYY-MM-DD") }} -
            {{ $dayjs(scope.row.endTime).format("YYYY-MM-DD") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="isRecommend" label="是否推荐" width="80">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isRecommend"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="handleRecommendStatusChange(scope.row._id)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="priority" label="权重" />
      <el-table-column prop="isDelete" label="已删除" width="80">
        <template slot-scope="scope">
          <span style="color: red;">{{ scope.row.isDelete ? '已删除' : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="500">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            @click="handleEdit(scope.row)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.row._id)"
          ></el-button>
          <el-button
            type="primary"
            icon="el-icon-sort"
            @click="setPriority(scope.row._id, scope.row.priority)"
            >调整优先级</el-button
          >
          <el-button
            type="success"
            @click="copy(scope.row)"
            >复制</el-button
          >
          <el-button
            :type="scope.row.enableRegister ? 'danger' : 'warning'"
            @click="addForm(scope.row)"
            >{{ scope.row.enableRegister ? '删除报名表单' : '添加报名表单' }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="!this.query"
      class="pager"
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="onCurrentPageChange"
    >
    </el-pagination>
    <edit-dialog
      :editDialogVisible="editDialogVisible"
      :formObj="formObj"
      @edit-success="getEvents"
      @close="dialogClose"
      type="edit"
    ></edit-dialog>
  </div>
</template>

<script>
import EditDialog from "./components/editDialog";
import _ from "loadsh";

export default {
  components: {
    EditDialog,
  },
  data() {
    return {
      editDialogVisible: false,
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      formObj: {},
      query: "",
    };
  },
  created() {
    this.getEvents();
  },
  watch: {
    query(newVal) {
      if (!newVal) {
        this.pageNum = 1
        this.getEvents()
      }
    }
  },
  methods: {
    getEvents() {
      this.$axios
        .get(`/admin_find_all?pageNum=${this.pageNum}&pageSize=${this.pageSize}`)
        .then((res) => {
          this.tableData = _.get(res, 'data.res.list')
          this.total = _.get(res, 'data.res.total')
          const output = _.cloneDeep(this.tableData)
          output.forEach((item) => {
            item.startTime = this.$dayjs(item.startTime).format("YYYY-MM-DD")
            item.endTime = this.$dayjs(item.endTime).format("YYYY-MM-DD")
          })
          console.log(output)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onCurrentPageChange(index) {
      this.pageNum = index
      this.getEvents()
    },
    handleEdit(row) {
      let tempData = _.cloneDeep(row);
      // 处理数组
      tempData.time = [];
      tempData.time.push(tempData.startTime, tempData.endTime);
      tempData.tagStr = tempData.tags.join(",");
      this.formObj = tempData;
      this.editDialogVisible = true;
    },
    handleDelete(_id) {
      this.$confirm("此操作将永久删除该赛事, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.requestDelete(_id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    requestDelete(_id) {
      this.$axios
        .delete(`/del/${_id}`, {
          headers: {
            "x-auth-token": sessionStorage.getItem("bde_token"),
          },
        })
        .then((res) => {
          if (res.data.code === "success") {
            this.$message.success("删除成功");
            this.getEvents();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          if(err.response.data.code === 'token_timeout') {
            this.$router.push('/login')
          }
        });
    },
    dialogClose() {
      this.editDialogVisible = false;
    },
    setPriority(id, priority) {
      this.$prompt(`请输入优先级，当前优先级为${priority}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^[1-9]\d*$/,
        inputErrorMessage: "请输入正整数",
      })
        .then(({ value }) => {
          this.$axios
            .post(
              "/set_priority",
              JSON.stringify({ priority: value, _id: id }),
              {
                headers: {
                  "x-auth-token": sessionStorage.getItem("bde_token"),
                },
              }
            )
            .then((res) => {
              if (res.data.code === "success") {
                this.$message.success("操作成功！");
                this.getEvents()
              } else {
                this.$message.error("请求失败！");
              }
            })
            .catch((err) => {
              this.$message.error(err.response.data.message);
              if(err.response.data.code === 'token_timeout') {
                this.$router.push('/login')
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    handleQuery() {
      if (!this.query) return this.$message.error("请输入关键字！");
      this.$axios
        .post("/query_by_keyword", { name: this.query })
        .then((res) => {
          this.tableData = res.data.res || [];
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          console.log(err);
        });
    },
    handleRecommendStatusChange(id) {
      this.$axios
        .post(
          "/change_recommend_status",
          { _id: id },
          {
            headers: {
              "x-auth-token": sessionStorage.getItem("bde_token"),
            }
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.code === "success") {
            this.$message.success("操作成功！");
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          console.log(err);
          if(err.response.data.code === 'token_timeout') {
            this.$router.push('/login')
          }
        });
    },
    copy(row) {
      const obj = _.cloneDeep(row)
      delete obj._id
      delete obj.__v
      this.$confirm("确定复制该赛事?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
          .post('/add_event', JSON.stringify(obj), {
            headers: {
              "x-auth-token": sessionStorage.getItem("bde_token"),
            },
          })
          .then((res) => {
            if (res.data.code === "success") {
              this.$message.success("操作成功！");
              this.getEvents();
            } else {
              this.$message.error("请求错误！");
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.response.data.message);
            if (err.response.data.code === "token_timeout") {
              this.$router.push("/login");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消复制",
          });
        });
    },
    addForm(row) {
      const id = row._id
      if (row.enableRegister) {
        this.$axios
          .post('/form-define/delete', JSON.stringify({
            id
          }), {
            headers: {
              "x-auth-token": sessionStorage.getItem("bde_token"),
            },
          })
          .then((res) => {
            if (res.data.code === "success") {
              this.$message.success("操作成功！");
              this.getEvents();
            } else {
              this.$message.error("请求错误！");
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.response.data.message);
            if (err.response.data.code === "token_timeout") {
              this.$router.push("/login");
            }
          });
        return
      }
      this.$router.push(`/add-form?id=${id}`)
    }
  },
};
</script>

<style scoped>
.query-input {
  width: 30%;
  margin-bottom: 40px;
}
.pager {
  margin-top: 20px;
}
</style>
