<template>
  <div class="wrapper">
    <div class="header">
      <div class="left-part">
        <div class="logo">
          <i class="el-icon-magic-stick icon-logo"></i>China Bodybuilding Event
          System Backstage
        </div>
      </div>
      <div class="right-part">
        <el-link type="primary" @click="logout">注销</el-link>
      </div>
    </div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu"
      mode="horizontal"
      background-color="#c04851"
      text-color="#fff"
      active-text-color="#fff"
      @select="handleSelect"
    >
      <el-menu-item index="eventManage">赛事管理</el-menu-item>
      <el-menu-item index="addEvent">添加赛事</el-menu-item>
      <el-menu-item index="noticeManage">通知管理</el-menu-item>
      <el-menu-item index="adsManage">推荐位管理</el-menu-item>
      <el-menu-item index="videoManage">视频管理</el-menu-item>
      <el-menu-item index="database">历史赛事数据库</el-menu-item>
      <el-menu-item index="formResult">报名数据大盘</el-menu-item>
      <el-menu-item index="equipmentManage">商品管理</el-menu-item>
      <el-submenu index="articleManage">
        <template slot="title">文章管理</template>
        <el-menu-item index="articleList">文章列表</el-menu-item>
        <el-menu-item index="addArticle">添加文章</el-menu-item>
      </el-submenu>
    </el-menu>
    <el-card class="main" shadow="hover">
      <router-view></router-view>
    </el-card>
  </div>
</template>

<script>
const routerMap = {
  eventManage: {
    path: "/manage",
  },
  addEvent: {
    path: "/add",
  },
  noticeManage: {
    path: "/notice",
  },
  adsManage: {
    path: "/ads",
  },
  videoManage: {
    path: "/video",
  },
  database: {
    path: "/database",
  },
  formResult: {
    path: "/form_result",
  },
  equipmentManage: {
    path: "/equipment",
  },
  addArticle: {
    path: "/add-article",
  },
  articleList: {
    path: "/article-list",
  }
};
export default {
  name: "Index",
  data() {
    return {
      activeIndex: "eventManage",
    };
  },
  methods: {
    handleSelect(index) {
      this.$router.push({
        path: routerMap[index].path,
      });
    },
    logout() {
      sessionStorage.removeItem("bde_token");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="css" scoped>
.wrapper {
  width: 100%;
  height: 100vh;
  margin-top: 0;
}

.header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0 80px;
}

.logo {
  color: #c04851;
  font-weight: bold;
  font-size: 22px;
}

.icon-logo {
  display: inline-block;
  margin-right: 5px;
  font-size: 25px;
}

.main {
  box-sizing: border-box;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  padding: 10px;
  font-size: 20px;
}

.el-menu {
  padding: 0 80px;
}
</style>
