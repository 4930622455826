import Vue from 'vue'
import router from './router/index'
import ElementUI from 'element-ui';
import './theme/index.css';
import App from './App.vue'
import axios from 'axios'
import dayjs from 'dayjs'
import TTUploader from 'tt-uploader';

import './assets/style/reset.css'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)

Vue.use(ElementUI);
Vue.config.productionTip = false

axios.defaults.headers.post['Content-Type'] = 'application/json'
Vue.prototype.$axios=axios
axios.defaults.baseURL = 'https://api2.jirou.fit/api'
// axios.defaults.baseURL = 'http://localhost:3000/api/'

Vue.prototype.$dayjs=dayjs


const ttUploader = new TTUploader({
  // 必填，在我的应用中申请的appid
  appId: 376982,
  // 必填，建议设置能识别用户的唯一标识id，用于上传出错时排查问题，不要传入非 ASCII编码
  userId: 'musclepro_admin',
  // 必填，图片上传相关配置
  imageConfig: {
    // 必填，申请的图片服务id
    serviceId: '700tmnc25s'
  }
})

Vue.prototype.$ttUploader = ttUploader

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
