<template>
  <div>
    <div class="filter-tools">
      <el-input
        placeholder="按文章标题查找"
        v-model="query"
        class="query-input"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleQuery"
        ></el-button>
      </el-input>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="_id" label="文章ID" width="150"></el-table-column>
      <el-table-column prop="title" label="文章标题"></el-table-column>
      <el-table-column
        prop="author"
        label="文章作者"
        width="100"
      ></el-table-column>
      <el-table-column prop="status" label="文章状态" width="80">
        <template slot-scope="scope">
          <el-tag :type="getStatusStyle(scope.row.status)">{{ getStatusLabel(scope.row.status) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="发布时间" width="180">
        <template slot-scope="scope">
          <span>
            {{
              $dayjs(scope.row.createdAt)
                .locale("zh-cn")
                .format("YYYY-MM-DD HH:mm:ss")
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            type="info"
            icon="el-icon-edit"
            @click="handleEdit(scope.row._id)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.row._id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="!query"
      class="pager"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onCurrentPageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import _ from "loadsh";
import { ARTICLE_STATUS } from "../components/constant";

export default {
  data() {
    return {
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      query: "",
      ARTICLE_STATUS
    };
  },
  created() {
    this.getData();
  },
  watch: {
    query() {
      this.getData();
    },
  },
  methods: {
    divide: _.divide,
    getData() {
      this.$axios
        .get(
          `/article/find_all?pageSize=${this.pageSize}&pageNum=${this.pageNum}`,
          {},
          {
            headers: {
              "x-auth-token": sessionStorage.getItem("bde_token"),
            },
          }
        )
        .then((res) => {
          this.tableData = _.get(res, "data.res.list");
          this.total = _.get(res, "data.res.total") || 0;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onCurrentPageChange(index) {
      this.pageNum = index;
      this.getData();
    },
    handleDelete(_id) {
      this.$confirm("此操作将永久删除该条信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.requestDelete(_id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleEdit(_id) {
      this.$router.push("/add-article?id=" + _id);
    },
    requestDelete(id) {
      this.$axios
        .post("/article/delete", JSON.stringify({ id }), {
          headers: {
            "x-auth-token": sessionStorage.getItem("bde_token"),
          },
        })
        .then((res) => {
          if (res.data.code === "success") {
            this.$message.success("删除成功");
            this.getData();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          if (err.response.data.code === "token_timeout") {
            this.$router.push("/login");
          }
        });
    },
    handleQuery() {
      if (!this.query) return this.$message.error("请输入关键词！");
      this.$axios
        .post("/article/find_by_title", { title: this.query })
        .then((res) => {
          this.tableData = res.data.res || [];
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          console.log(err);
        });
    },
    getStatusLabel(value) {
      const status = this.ARTICLE_STATUS.find((item) => item.value === value);
      return status ? status.label : "";
    },
    getStatusStyle(value) {
      const status = this.ARTICLE_STATUS.find((item) => item.value === value);
      return status ? status.style : "";
    },
  },
};
</script>

<style scoped>
.query-input {
  width: 30%;
  margin-bottom: 40px;
}
.pager {
  margin-top: 20px;
}
.color-male {
  color: #409eff;
}
.color-male-female {
  color: #f56c6c;
}
.btn-export {
  margin-left: 20px;
}
</style>
