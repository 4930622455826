<template>
  <div>
    <div class="form">
      <article-form></article-form>
    </div>
  </div>
</template>

<script>
import ArticleForm from '../components/articleForm'

export default {
  components: {
    ArticleForm
  }
}
</script>