export default {
  data() {
    return {
      dialogImageUrl: "",
      imgPreviewDialogVisible: false,
      fileList: []
    }
  },
  methods: {
    uploadImg(param) {
      // 上传图片到火山引擎
      this.$axios
        .post("/config/get_temp_upload_key", null, {
          headers: {
            "x-auth-token": sessionStorage.getItem("bde_token"),
          },
        })
        .then((res) => {
          if (res.status !== 200) {
            console.log(res)
            this.$message.error('火山引擎stsToken获取失败，请检查控制台错误信息！')
          }
          const stsToken = res.data.res.data || {};
          const fileKey = this.$ttUploader.addImageFile({
            // 必填，待上传的Blob/File文件
            file: param.file,
            // 必填，从服务端拿到的token，token为一个对象类型，详见下方 addImageFile 方法说明
            stsToken,
          });
          // 开始上传
          this.$ttUploader.start(fileKey);
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.imgPreviewDialogVisible = true;
    },
  }
}