<template>
  <div class="wrapper">
    <el-card class="box-card">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" type="password"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="login">登录</el-button>
          <el-button @click="reset">清空</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    reset() {
      this.$refs['form'].resetFields()
    },
    login() {
      this.$refs["form"].validate((valid) => {
        if(!valid) return
        this.$axios
          .post("/users/login", JSON.stringify(this.form))
          .then((res) => {
            if (res.data.code === "success") {
              this.$message.success("登录成功！");
              sessionStorage.setItem('bde_token',res.data.res.token)
              this.$router.push('/')
            } else {
              this.$message.error("请求错误！");
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error("出错了！");
          });
      })
    }
  }
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box-card {
  box-sizing: border-box;
  width: 500px;
  padding: 20px 20px 0 0;
}
</style>
