<template>
  <div>
    <el-dialog title="编辑" :visible.sync="showDialog" :before-close="beforeClose">
      <event-form :formObj="formObj" :type="type" @edit-success="editSuccess"></event-form>
    </el-dialog>
  </div>
</template>

<script>
import EventForm from '../../components/eventForm'

export default {
  components: {
    EventForm
  },
  props: {
    formObj: {
      type:Object,
      default: () => ({})
    },
    editDialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      showDialog: false
    }
  },
  watch: {
    editDialogVisible(val) {
      this.showDialog = val
    }
  },
  methods: {
    beforeClose() {
      this.$emit('close')
    },
    editSuccess() {
      this.showDialog = false
      this.$emit('edit-success')
      this.beforeClose()
    }
  }
}
</script>
