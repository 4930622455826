<template>
  <div>
    <div class="filter-tools">
      <el-input placeholder="请输入比赛ID" v-model="query" class="query-input">
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleQuery"
        ></el-button>
      </el-input>
      <el-button
        class="btn-export"
        icon="el-icon-download"
        type="primary"
        @click="exportExcel"
      ></el-button>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="_id" label="ID" width="150"></el-table-column>
      <el-table-column prop="eventId" label="赛事ID" width="150"></el-table-column>
      <el-table-column prop="eventName" label="比赛名称" width="330"></el-table-column>
      <el-table-column prop="name" label="运动员姓名" width="100"></el-table-column>
      <el-table-column prop="sex" label="性别" width="50">
        <template slot-scope="scope">
          <span v-if="scope.row.sex" class="color-male">♂</span>
          <span v-else class="color-female">♀</span>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="手机号" width="100"></el-table-column>
      <el-table-column prop="wechat" label="微信号" width="100"></el-table-column>
      <el-table-column prop="weMediaId" label="自媒体号" width="100"></el-table-column>
      <el-table-column prop="weMediaFans" label="自媒体全网粉丝数" width="100"></el-table-column>
      <el-table-column prop="items" label="报名项目" width="100">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.items" :key="index">{{ item.item + item.group }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="orderNo" label="报名订单号" width="120"></el-table-column>
      <el-table-column prop="enableCourse" label="是否报名课程" width="120">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.enableCourse" type="success" effect="dark" size="mini">是</el-tag>
          <el-tag v-else type="danger" effect="dark" size="mini">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="school" label="学校" width="120"></el-table-column>
      <el-table-column prop="grade" label="年级" width="120"></el-table-column>
      <el-table-column prop="age" label="年龄" width="50"></el-table-column>
      <el-table-column prop="isPaid" label="是否已支付" width="50">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isPaid" type="success" effect="dark" size="mini">是</el-tag>
          <el-tag v-else type="danger" effect="dark" size="mini">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="payableAmount" label="应付金额" width="50">
        <template slot-scope="scope">
          {{ divide(scope.row.payableAmount, 100) }}
        </template>
      </el-table-column>
      <el-table-column prop="paymentAmount" label="支付金额" width="50">
        <template slot-scope="scope">
          {{ divide(scope.row.paymentAmount, 100) }}
        </template>
      </el-table-column>
      <el-table-column label="比赛时间" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.fuzzyTime">{{ scope.row.fuzzyTime }}</span>
          <span>
            {{ $dayjs(scope.row.eventStartTime).format("YYYY-MM-DD") }} -
            {{ $dayjs(scope.row.eventEndTime).format("YYYY-MM-DD") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.row._id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="!query"
      class="pager"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onCurrentPageChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import _ from "loadsh";
import { utils, write } from 'xlsx';

export default {
  data() {
    return {
      editDialogVisible: false,
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      formObj: {},
      query: "",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    divide: _.divide,
    getData() {
      this.$axios
        .get(`/form-result/find_all?pageSize=${this.pageSize}&pageNum=${this.pageNum}`, {}, {
            headers: {
              "x-auth-token": sessionStorage.getItem("bde_token"),
            },
          })
        .then((res) => {
          this.tableData = _.get(res, 'data.res.list')
          this.total = _.get(res, 'data.res.total') || 0
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onCurrentPageChange(index) {
      this.pageNum = index
      this.getData()
    },
    handleDelete(_id) {
      this.$confirm("此操作将永久删除该条信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.requestDelete(_id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    requestDelete(id) {
      this.$axios.post("/form-result/delete",
          JSON.stringify({ id }),
          {
            headers: {
              "x-auth-token": sessionStorage.getItem("bde_token"),
            },
          })
        .then((res) => {
          if (res.data.code === "success") {
            this.$message.success("删除成功");
            this.getData();
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          if(err.response.data.code === 'token_timeout') {
            this.$router.push('/login')
          }
        });
    },
    handleQuery() {
      if (!this.query) return this.$message.error("请输入赛事ID！");
      this.$axios
        .post("/form-result/find_by_id", { id: this.query })
        .then((res) => {
          this.tableData = res.data.res || [];
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          console.log(err);
        });
    },
    exportExcel() {
      const list = []
      this.tableData.forEach((data) => {
        const temp = {...data}
        const itemsArray = []
        temp.items.forEach((item) => {
          itemsArray.push(item.item + item.group)
        })
        temp.items = itemsArray.join('、')
        temp.sex = temp.sex ? '男' : '女'
        temp.isPaid = temp.isPaid ? '是✅' : '否❌'
        temp.payTime = this.$dayjs(temp.payTime).format("YYYY-MM-DD HH:mm:ss")
        temp.eventStartTime = this.$dayjs(temp.eventStartTime).format("YYYY-MM-DD")
        temp.eventEndTime = this.$dayjs(temp.eventEndTime).format("YYYY-MM-DD")
        temp.payableAmount = _.divide(temp.payableAmount, 100)
        temp.paymentAmount = _.divide(temp.paymentAmount, 100)

        list.push(temp)
      }); // 获取需要导出的数据，假设为this.tableData
      const worksheet = utils.json_to_sheet(list);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'export.xlsx');
    },
    saveAsExcelFile(buffer, fileName) {
      const data = new Blob([buffer], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(data);
      link.download = fileName;
      link.click();
    }
  },
};
</script>

<style scoped>
.query-input {
  width: 30%;
  margin-bottom: 40px;
}
.pager {
  margin-top: 20px;
}
.color-male {
  color: #409EFF;
}
.color-male-female {
  color: #F56C6C;
}
.btn-export {
  margin-left: 20px;
}
</style>
