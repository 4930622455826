<template>
  <div>
    <div class="form">
      <event-form></event-form>
    </div>
  </div>
</template>

<script>
import EventForm from '../components/eventForm'

export default {
  components: {
    EventForm
  }
}
</script>