<template>
  <div>
    商品管理
    <!-- 操作按钮取 -->
    <div class="btns-bar">
      <el-button type="primary" @click="showAddDialog = true"
        >添加商品</el-button
      >
    </div>
    <!-- 表格展示 -->
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column prop="_id" label="商品ID"></el-table-column>
      <el-table-column
        prop="title"
        label="商品标题"
        width="380"
      ></el-table-column>
      <el-table-column prop="headImg" label="商品头图">
        <template slot-scope="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.headImg[0]"
            fit="contain"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="minPrice" label="最小价格"></el-table-column>
      <el-table-column prop="maxPrice" label="最高价格"></el-table-column>
      <el-table-column prop="order" label="权重"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            icon="el-icon-delete"
            @click="handleDelete(scope.row._id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      background
      layout="prev, pager, next"
      :total="total"
      :page-size="pageSize"
      :current-page.sync="pageNum"
      @current-change="onCurrentPageChange"
    >
    </el-pagination>
    <!-- 添加对话框 -->
    <el-dialog title="添加商品" :visible.sync="showAddDialog" width="50%">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="副标题" prop="subTitle">
          <el-input v-model="form.subTitle"></el-input>
        </el-form-item>
        <el-form-item label="是否二手" prop="isTwoHand">
          <el-switch
            v-model="form.isTwoHand"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
        <el-form-item label="最低价格" prop="minPrice">
          <el-input v-model.number="form.minPrice"></el-input>
        </el-form-item>
        <el-form-item label="最高价格" prop="maxPrice">
          <el-input v-model.number="form.maxPrice"></el-input>
        </el-form-item>
        <el-form-item label="权重" prop="order">
          <el-input v-model="form.order"></el-input>
        </el-form-item>
        <el-form-item label="商品简介" prop="desc">
          <el-input v-model="form.desc" :rows="3" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="微信" prop="wechat">
          <el-input v-model="form.wechat"></el-input>
        </el-form-item>
        <el-form-item label="商品头图" prop="headImg">
          <el-upload
            class="image-upload"
            action=""
            :http-request="onUploadPoster"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :file-list="fileList"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="imgPreviewDialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addEquipment">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'loadsh';
import uploadMixin from "../../mixin/upload";

export default {
  data() {
    return {
      showAddDialog: false,
      tableData: [],
      form: {
        title: "",
        subTitle: "",
        minPrice: "",
        maxPrice: "",
        phone: "",
        wechat: "",
        isTwoHand: true,
        desc: "",
        headImg: [],
        order: 0,
      },
      total: 0,
      pageNum: 1,
      pageSize: 10,
      rules: {
        title: [
          { required: true, message: "请输入广告标题", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        minPrice: [
          { required: true, message: "请输入最小价格", trigger: "blur" },
        ],
        headImg: [
          { required: true, message: "请上传商品头图", trigger: "blur" }
        ],
      },
    };
  },
  mixins: [uploadMixin],
  created() {
    this.getEquipmentList();
  },
  methods: {
    onCurrentPageChange(index) {
      this.pageNum = index
      this.getEquipmentList();
    },
    getEquipmentList() {
      this.$axios
        .get(`/equipment/find_all?pageSize=${this.pageSize}&pageNum=${this.pageNum}`)
        .then((res) => {
          this.tableData = _.get(res, 'data.res.list')
          this.total = _.get(res, 'data.res.total') || 0
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
        });
    },
    addEquipment() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return this.$message.error("表单信息填写错误，请根据提示检查！");
        }
        this.$axios
          .post("/equipment/add", JSON.stringify(this.form), {
            headers: {
              "x-auth-token": sessionStorage.getItem("bde_token"),
            },
          })
          .then((res) => {
            if (res.data.code === "success") {
              this.$message.success("添加成功！");
            } else {
              this.$message.error("请求错误！");
            }
            this.showAddDialog = false;
            this.getEquipmentList()
          })
          .catch((err) => {
            this.$message.error(err.response.data.message);
          });
      });
    },
    handleDelete(id) {
      this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.requestDelete(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    requestDelete(id) {
      this.$axios
        .post("/equipment/delete", JSON.stringify({ id: id }), {
          headers: {
            "x-auth-token": sessionStorage.getItem("bde_token"),
          },
        })
        .then((res) => {
          if (res.data.code === "success") {
            this.$message.success("删除成功");
            this.getEquipmentList()
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message);
          if (err.response.data.code === "token_timeout") {
            this.$router.push("/login");
          }
        });
    },
    onUploadPoster(param) {
      const that = this;
      // 绑定进度监听函数
      this.$ttUploader.on("complete", function (data) {
        if (data.type === "success") {
          console.log('上传成功触发')
          // that.form.headImg =
          //   "https://cdn.jirou.fit/" +
          //   data.uploadResult.ImageUri +
          //   "~tplv-700tmnc25s-yasuo.image";
          const imgUrl = "https://cdn.jirou.fit/" + data.uploadResult.ImageUri + "~tplv-700tmnc25s-yasuo.image"
          if (!that.form.headImg.includes(imgUrl)) {
            that.form.headImg.push("https://cdn.jirou.fit/" + data.uploadResult.ImageUri + "~tplv-700tmnc25s-yasuo.image")
            that.$message({
              type: "success",
              message: data.extra.message,
            });
          }
        }
      });
      // 上传图片到火山引擎
      this.uploadImg(param);
    },
  },
};
</script>

<style scoped>
.btns-bar {
  margin: 20px 0;
}
.image-upload {
  margin-bottom: 10px;
}
.pager {
  margin-top: 20px;
}
</style>
