<template>
  <div>
    <el-form ref="form" :rules="rules" :model="form" label-width="150px">
      <el-form-item label="是否收集身份证号">
        <el-switch
          v-model="form.collectIdNumber"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="是否收集收货地址">
        <el-switch
          v-model="form.collectAddress"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="是否开启战队填写">
        <el-switch
          v-model="form.enableTeam"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="是否收集年龄">
        <el-switch
          v-model="form.collectAge"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="是否收集自媒体信息">
        <el-switch
          v-model="form.collectWeMedia"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="是否强制预定油彩">
        <el-switch
          v-model="form.enablePaint"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item v-if="form.enablePaint" label="油彩价格" prop="paintPrice">
        <el-input
          type="number"
          placeholder="请输入油彩价格"
          v-model="form.paintPrice"
        />
      </el-form-item>
      <el-form-item label="是否开启课程收费">
        <el-switch
          v-model="form.enableCourse"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item
        v-if="form.enableCourse"
        label="课程价格"
        prop="coursePrice"
      >
        <el-input
          type="number"
          placeholder="请输入课程价格"
          v-model="form.coursePrice"
        />
      </el-form-item>
      <el-form-item v-if="form.enableCourse" label="课程描述" prop="courseDesc">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入课程描述"
          v-model="form.courseDesc"
        >
        </el-input>
      </el-form-item>
      <el-form-item v-if="form.enableCourse" label="课程介绍链接">
        <el-input placeholder="请输入课程介绍链接" v-model="form.courseUrl">
        </el-input>
      </el-form-item>
      <el-form-item label="是否收集学校信息">
        <el-switch
          v-model="form.collectSchool"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="收费模式" prop="feeType">
        <el-radio-group v-model="form.feeType">
          <el-radio :label="1">主兼项收费</el-radio>
          <el-radio :label="2">按项目单独收费</el-radio>
        </el-radio-group>
      </el-form-item>
      <template v-if="form.feeType === 1">
        <el-form-item label="主项费用" prop="mainFee">
          <el-input
            type="number"
            placeholder="请输入主项费用"
            v-model="form.mainFee"
          />
        </el-form-item>
        <el-form-item label="兼项费用" prop="subFee">
          <el-input
            type="number"
            placeholder="请输入兼项费用"
            v-model="form.subFee"
          />
        </el-form-item>
      </template>
      <el-form-item label="添加报名项" prop="items">
        <div class="items-display">
          <div
            v-for="(item, index) in formObj.items"
            :key="index"
            class="items-display-parent"
          >
            <el-tag size="small" effect="dark"
              >{{ item.title }}({{ item.price }})</el-tag
            >
            <div
              v-for="(group, index) in item.groups"
              :key="index"
              class="items-display-children"
            >
              -- {{ group }}
            </div>
          </div>
        </div>
        <div class="item-card">
          <div class="item-card-item">
            <span>比赛项目（例如男子传统健美、男子古典健美）</span>
            <el-input placeholder="请输入项目名称" v-model="itemInfo.title" />
          </div>
          <div class="item-card-item">
            <span>项目分组（支持粘贴，每个项目一行）</span>
            <el-input
              type="textarea"
              autosize
              placeholder="请输入项目名称"
              :rows="4"
              v-model="itemInfo.groups"
            />
          </div>
          <!-- 收费模式为“按组别单独收费”时需填写 -->
          <div v-if="form.feeType === 2" class="item-card-item">
            <span>该组收费标准</span>
            <el-input
              type="number"
              placeholder="请输入该组别的费用"
              v-model="itemInfo.price"
            />
          </div>
          <el-button class="item-card-save" type="primary" @click="onItemSave"
            >保存</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="费用说明文案">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入费用说明"
          v-model="form.costExplanation"
        />
      </el-form-item>
      <el-form-item label="联系方式">
        <template>
          <div class="register-item">
            <span class="label">联系方式类型</span>
            <el-select
              v-model="form.register.type"
              placeholder="请选择联系方式类型"
            >
              <el-option
                v-for="item in REGISTER_FORM_TYPE"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="register-item">
            <span class="label"
              >联系方式（微信,电话,微信二维码,微信群二维码）</span
            >
            <el-upload
              v-if="[2, 3].includes(form.register.type)"
              action=""
              :http-request="onUploadQRcode"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :limit="1"
              :file-list="qrcodeList"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-input
              v-model="form.register.url"
              placeholder="请根据选择的联系方式类型填写联系方式信息"
            ></el-input>
          </div>
        </template>
      </el-form-item>
      <el-form-item label="Webhook地址" prop="webhook">
        <el-input
          placeholder="请输入飞书群机器人Webhook地址"
          v-model="form.webhook"
        />
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          autosize
          placeholder="请输入备注内容"
          v-model="form.remarks"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="关联文案" prop="connectionDesc">
        <el-input placeholder="请输入关联文案" v-model="form.connectionDesc" />
      </el-form-item>
      <el-form-item label="关联链接" prop="connectionUrl">
        <el-input
          placeholder="请输入关联链接（网页、小程序短链）"
          v-model="form.connectionUrl"
        />
      </el-form-item>

      <el-form-item class="footer-btn">
        <el-button type="primary" @click="onSubmit">{{
          type === "edit" ? "保存修改" : "立即创建"
        }}</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { REGISTER_FORM_TYPE } from "./constant";
import uploadMixin from "../../mixin/upload";

export default {
  props: {
    formObj: {
      type: Object,
      default: () => ({
        collectIdNumber: false,
        collectAddress: false,
        enableTeam: false,
        enablePaint: false,
        paintPrice: "",
        collectSchool: false,
        collectAge: true,
        collectWeMedia: false,
        enableCourse: false,
        coursePrice: "",
        courseUrl: "",
        feeType: 1,
        mainFee: "",
        subFee: "",
        register: {
          type: 0,
          url: "",
        },
        costExplanation: "",
        webhook: "",
        remarks: "",
        items: [],
        connectionDesc: "",
        connectionUrl: ""
      }),
    },
    type: {
      type: String,
      default: "add",
    },
  },
  mixins: [uploadMixin],
  data() {
    return {
      REGISTER_FORM_TYPE,
      showFuzzyTime: false,
      addedEvents: [],
      rules: {
        items: [
          {
            type: "array",
            required: true,
            message: "请至少添加一个比赛项目",
            trigger: "change",
          },
        ],
        paintPrice: [
          {
            required: true,
            message: "请填写油彩费用",
            trigger: "blur",
          },
        ],
        mainFee: [
          {
            required: true,
            message: "请填写主项费用",
            trigger: "blur",
          },
        ],
        subFee: [
          {
            required: true,
            message: "请填写兼项费用",
            trigger: "blur",
          },
        ],
        coursePrice: [
          {
            required: true,
            message: "请填写课程价格",
            trigger: "blur",
          },
        ],
        courseDesc: [
          {
            required: true,
            message: "请填写课程描述",
            trigger: "blur",
          },
        ],
      },
      qrcodeList: [],
      itemInfo: {
        title: "",
        groups: "",
        price: "",
      },
    };
  },
  computed: {
    form() {
      return this.formObj;
    },
  },
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (!valid) {
          return this.$message.error("表单信息填写错误，请根据提示检查！");
        }
        if (!this.formObj.items.length) {
          return this.$message.error("项目不能为空！");
        }
        if (!this.formObj.register.url) {
          return this.$message.error("联系方式不能为空！");
        }
        const url = "/form-define/add";
        const params = {
          eventId: this.$route.query.id,
          ...this.form,
        };
        console.log(params, url);
        this.$axios
          .post(url, JSON.stringify(params), {
            headers: {
              "x-auth-token": sessionStorage.getItem("bde_token"),
            },
          })
          .then((res) => {
            if (res.data.code === "success") {
              this.$message.success("操作成功！");
              this.$emit("edit-success");
              this.addedEvents = [];
              this.$router.push("/");
            } else {
              this.$message.error("请求错误！");
            }
          })
          .catch((err) => {
            console.log(err);
            this.$message.error(err.response.data.message);
            if (err.response.data.code === "token_timeout") {
              this.$router.push("/login");
            }
          });
      });
    },

    addEvent() {
      this.$prompt("请输入比赛项目名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.{2,50}$/,
        inputErrorMessage: "项目名称格式不正确",
      })
        .then(({ value }) => {
          this.addedEvents.push(value);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    handleClose(tag) {
      this.form.events.splice(this.form.events.indexOf(tag), 1);
    },
    onUploadPoster(param) {
      const that = this;
      // 绑定进度监听函数
      this.$ttUploader.on("complete", function (data) {
        if (data.type === "success") {
          that.formObj.poster =
            "https://cdn.jirou.fit/" +
            data.uploadResult.ImageUri +
            "~tplv-700tmnc25s-yasuo.image";
        }
        that.$message({
          type: "success",
          message: data.extra.message,
        });
      });
      // 上传图片到火山引擎
      this.uploadImg(param);
    },
    onUploadQRcode(param) {
      const that = this;
      // 绑定进度监听函数
      this.$ttUploader.on("complete", function (data) {
        if (data.type === "success")
          that.formObj.register.url =
            "https://cdn.jirou.fit/" +
            data.uploadResult.ImageUri +
            "~tplv-700tmnc25s-yasuo.image";
        that.$message({
          type: "success",
          message: data.extra.message,
        });
      });
      // 上传图片到火山引擎
      this.uploadImg(param);
    },
    onItemSave() {
      if (!this.itemInfo.title || !this.itemInfo.groups) {
        return this.$message.error("请输入项目名称和项目组别");
      }
      const temp = {
        title: this.itemInfo.title,
        price: this.itemInfo.price,
        groups: this.itemInfo.groups.split("\n"),
      };
      this.formObj.items.push(temp);
    },
  },
};
</script>

<style scoped>
.register-item {
  min-height: 50px;
}

.register-item > .label {
  display: inline-block;
  margin-right: 10px;
}

.contact-item {
  margin-bottom: 20px;
}

.contact-item > .label {
  margin: 0 10px 0 20px;
}

.contact-item > span:first-child {
  margin-left: 0;
}

.footer-btn {
  margin-top: 100px;
}

.text-btn {
  display: inline-block;
  margin-left: 20px;
}

.image-upload {
  margin-bottom: 10px;
}

.item-card {
  margin-top: 10px;
  border-radius: 20px;
  border: 1px dashed #666;
  padding: 20px;
}
.item-card-save {
  margin-top: 10px;
}
.items-display-parent {
  line-height: 20px;
}
.items-display-children {
  margin-left: 20px;
  line-height: 20px;
  color: #666;
}
</style>
