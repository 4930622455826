<template>
  <div>
    <div class="form">
      <register-form></register-form>
    </div>
  </div>
</template>

<script>
import RegisterForm from '../components/registerForm'

export default {
  components: {
    RegisterForm
  }
}
</script>