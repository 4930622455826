
/**
 * 比赛项目配置
 */

const EVENTS_CONFIG = [
  {
    name: '公开组男子传统健美',
    value: 0
  },
  {
    name: '健美元老组',
    value: 1
  },
  {
    name: '公开组男子古典健美',
    value: 2
  },
  {
    name: '公开组男子健体',
    value: 3
  },
  {
    name: '公开组女子比基尼健身',
    value: 4
  },
  {
    name: '公开组男子健身模特',
    value: 5
  },
  {
    name: '公开组女子健身模特',
    value: 6
  },
  {
    name: '公开组男子体育模特',
    value: 7
  },
  {
    name: '公开组女子体育模特',
    value: 8
  },
  {
    name: '公开组女子形体',
    value: 9
  },
  {
    name: '公开组女子健美',
    value: 10
  },
  {
    name: '公开组女子健体',
    value: 11
  },
  {
    name: '公开组女子美臀',
    value: 12
  },
  {
    name: '公开组男子健身',
    value: 13
  },
  {
    name: '公开组女子健身',
    value: 14
  },
  {
    name: 'Cosplay',
    value: 15
  },
  {
    name: '公开组型格健体',
    value: 16
  },
  {
    name: '男女混双',
    value: 17
  },
  {
    name: '女神模特',
    value: 100
  },
  {
    name: '大学生组男子传统健美',
    value: 18
  },
  {
    name: '大学生组男子古典健美',
    value: 19
  },
  {
    name: '大学生组男子健体',
    value: 20
  },
  {
    name: '大学生组女子比基尼健身',
    value: 21
  },
  {
    name: '大学生组男子健身模特',
    value: 22
  },
  {
    name: '大学生组女子健身模特',
    value: 23
  },
  {
    name: '新秀组男子传统健美',
    value: 24
  },
  {
    name: '新秀组男子古典健美',
    value: 25
  },
  {
    name: '新秀组男子健体',
    value: 26
  },
  {
    name: '新秀组女子比基尼健身',
    value: 27
  },
  {
    name: '新秀组男子健身模特',
    value: 28
  },
  {
    name: '新秀组女子健身模特',
    value: 29
  },
  {
    name: '少年组男子传统健美',
    value: 30
  },
  {
    name: '少年组男子古典健美',
    value: 31
  },
  {
    name: '少年组男子健体',
    value: 32
  },
  {
    name: '少年组女子比基尼健身',
    value: 33
  },
  {
    name: '少年组男子健身模特',
    value: 34
  },
  {
    name: '少年组女子健身模特',
    value: 35
  },
  {
    name: '新人组男子传统健美',
    value: 36
  },
  {
    name: '新人组男子古典健美',
    value: 37
  },
  {
    name: '新人组男子健体',
    value: 38
  },
  {
    name: '新人组女子比基尼健身',
    value: 39
  },
  {
    name: '新人组男子健身模特',
    value: 40
  },
  {
    name: '新人组女子健身模特',
    value: 41
  }
]

const GROUPS_CONFIG = [
  {
    name: '公开组',
    value: 'public'
  },
  {
    name: '青年组',
    value: 'youth'
  },
  {
    name: '新秀组',
    value: 'new'
  },
  {
    name: '首秀组',
    value: 'first'
  },
  {
    name: '新人组',
    value: 'newbee'
  },
  {
    name: '大学生组',
    value: 'student'
  },
  {
    name: '高校组',
    value: 'college'
  },
  {
    name: '在校组',
    value: 'student-in-school'
  },
  {
    name: '院校组',
    value: 'student-in-school-2'
  },
  {
    name: '毕业组',
    value: 'student-graduation'
  },
  {
    name: '元老组',
    value: 'elder'
  },
  {
    name: '本地组',
    value: 'local'
  },
  {
    name: '少年组',
    value: 'juvenile'
  },
  {
    name: '大师组',
    value: 'master'
  },
  {
    name: '逆龄组',
    value: 'niling'
  },
  {
    name: '职业组',
    value: 'pro'
  },
  {
    name: '辣妈组',
    value: 'lama'
  },
  {
    name: '残障组',
    value: 'canzhang'
  },
  {
    name: '模特组',
    value: 'model'
  },
  {
    name: '中学组',
    value: 'middleSchool'
  },
  {
    name: '自然组',
    value: 'ziran'
  },
  {
    name: '业余组',
    value: 'yeyu'
  },
  {
    name: '追梦组',
    value: 'free'
  }
]

const RANK_CONFIG = [
  // -1-未定义，0-校赛，1-区赛，2-市赛，3-省赛，4-区域赛，5-全国赛，6-亚洲级，7-世界级，8-商业赛，9-职业卡资格赛，10-职业赛，11-奥赛
  {
    level: '未定义',
    value: -1
  },
  {
    level: '校赛',
    value: 0
  },
  {
    level: '区赛',
    value: 1
  },
  {
    level: '市赛',
    value: 2
  },
  {
    level: '省赛',
    value: 3
  },
  {
    level: '区域赛',
    value: 4
  },
  {
    level: '全国赛',
    value: 5
  },
  {
    level: '亚洲级',
    value: 6
  },
  {
    level: '世界级',
    value: 7
  },
  {
    level: '商业赛',
    value: 8
  },
  {
    level: '职业卡资格赛',
    value: 9
  },
  {
    level: '职业赛',
    value: 10
  },
  {
    level: '奥赛',
    value: 11
  }
]

const REGISTER_TYPE = [
  // 报名方式类型 0-报名链接，1-二维码报名，2-邮箱报名, 3-微信报名，4-电话报名，5-线下报名，6-其他方式报名
  {
    label: '报名链接',
    value: 0
  },
  {
    label: '报名二维码',
    value: 1
  },
  {
    label: '邮箱报名',
    value: 2
  },
  {
    label: '微信报名',
    value: 3
  },
  {
    label: '电话报名',
    value: 4
  },
  {
    label: '线下报名',
    value: 5
  },
  {
    label: '其他方式报名',
    value: 6
  }
]

const CONTACT_TYPE = [
  // 类型：0-微信,1-电话,2-微信同电话,3-邮箱
  {
    label: '微信',
    value: 0
  },
  {
    label: '电话',
    value: 1
  },
  {
    label: '微信同电话',
    value: 2
  },
  {
    label: '邮箱',
    value: 3
  }
]

const REGISTER_FORM_TYPE = [
  // 类型：0-微信,1-电话,2-微信二维码,3-微信群二维码
  {
    label: '微信',
    value: 0
  },
  {
    label: '电话',
    value: 1
  },
  {
    label: '微信二维码',
    value: 2
  },
  {
    label: '微信群二维码',
    value: 3
  }
]


const REGION_TYPE_ONE = [
  {
    label: '未定义',
    value: -1
  },
  {
    label: '京津冀',
    value: 0
  },
  {
    label: '东三省',
    value: 1
  },
  {
    label: '陕甘宁',
    value: 2
  },
  {
    label: '粤港澳',
    value: 3
  },
  {
    label: '江浙沪',
    value: 4
  },
  {
    label: '川渝',
    value: 7
  },
  {
    label: '云贵',
    value: 8
  },
  {
    label: '青藏地区',
    value: 9
  },
  {
    label: '台湾省',
    value: 10
  }
]

const REGION_TYPE_TWO = [
  {
    label: '未定义',
    value: -1
  },
  {
    label: '西北地区',
    value: 0
  },
  {
    label: '华北地区',
    value: 1
  },
  {
    label: '华中地区',
    value: 2
  },
  {
    label: '华南地区',
    value: 3
  },
  {
    label: '华东地区',
    value: 4
  },
  {
    label: '西南地区',
    value: 5
  },
  {
    label: '东北地区',
    value: 6
  },
  {
    label: '东南地区',
    value: 7
  }
]

const ARTICLE_TYPE = [
  {
    label: '新闻',
    value: 0
  },
  {
    label: '成绩公告',
    value: 1
  },
  {
    label: '百科',
    value: 2
  }
]

const ARTICLE_STATUS = [
  {
    label: '草稿',
    value: 0,
    style: "info"
  },
  {
    label: '正常',
    value: 1,
    style: "success"
  },
  {
    label: '删除',
    value: 2,
    style: "danger"
  }
]

const RULE_TYPE = [
  {
    label: '其他',
    value: 1
  },
  {
    label: 'IFBB职业联盟',
    value: 2
  },
  {
    label: 'IFBB精英联盟',
    value: 3
  }
]

export {
  EVENTS_CONFIG,
  GROUPS_CONFIG,
  RANK_CONFIG,
  REGISTER_TYPE,
  CONTACT_TYPE,
  REGISTER_FORM_TYPE,
  REGION_TYPE_ONE,
  REGION_TYPE_TWO,
  ARTICLE_TYPE,
  ARTICLE_STATUS,
  RULE_TYPE
}